<template>
  <div>
    <v-dialog :max-width="750" v-model="showModal" @click:outside="closeModal">
      <v-card>
        <v-btn class="mt-2 ml-3" icon @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <section class="pt-0">
          <v-row>
            <v-col cols="12" xl="5" lg="5" md="5" sm="5" xs="12" class="pb-0">
              <v-card-title class="mx-3 text-h5 secondary--text"
                >{{ pagoEditable ? "Edición" : "Registro" }} de
                pago</v-card-title
              >
            </v-col>
          </v-row>

          <v-card-text class="pb-0">
            <section>
              <v-row>
                <v-col cols="8">
                  <v-select
                    label="Etapa *"
                    outlined
                    :items="etapasList"
                    :error-messages="etapaErrors"
                    @blur="$v.pagoEtapa.id_etapa.$touch()"
                    v-model="pagoEtapa.id_etapa"
                    item-value="id"
                    item-text="etapa"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <div class="d-flex">
                    <div class="text-center mr-3">
                      <p class="text-h4 mb-0">
                        ${{ metricasPagos.total_adjudicado? metricasPagos.total_adjudicado.toFixed(2):"" }}
                      </p>
                      <v-card-subtitle class="justify-center my-0 py-0">
                        <p class="my-0 py-0 px-6">Monto a pagar</p>
                      </v-card-subtitle>
                      <v-progress-linear
                        color="yellow"
                        rounded
                        value="100"
                        class="my-0 py-0"
                      ></v-progress-linear>
                    </div>

                    <div class="text-center ml-3">
                      <p class="text-h4 mb-0">
                        ${{ metricasPagos.monto_restante? metricasPagos.monto_restante:"" }}
                      </p>
                      <v-card-subtitle class="justify-center my-0 py-0">
                        <p class="my-0 py-0">Monto restante a pagar</p>
                      </v-card-subtitle>
                      <v-progress-linear
                        color="success"
                        rounded
                        value="100"
                        class="my-0 py-0"
                      ></v-progress-linear>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    label="Monto *"
                    outlined
                    type="number"
                    prepend-inner-icon="mdi-currency-usd"
                    v-model="pagoEtapa.monto"
                    :error-messages="montoErrors"
                    @blur="$v.pagoEtapa.monto.$touch()"
                    @keydown="filterKey"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formattedDate"
                        label="Fecha *"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        :error-messages="fechaErrors"
                        @blur="$v.pagoEtapa.fecha.$touch()"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="es"
                      v-model="pagoEtapa.fecha"
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="4">
                  <v-menu
                    ref="menuHora"
                    v-model="menuHora"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="pagoEtapa.hora"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formattedHora"
                        label="Hora *"
                        prepend-inner-icon="mdi-clock-time-four-outline"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        :error-messages="horaErrors"
                        @blur="$v.pagoEtapa.hora.$touch()"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menuHora"
                      v-model="pagoEtapa.hora"
                      full-width
                      @click:minute="$refs.menuHora.save(pagoEtapa.hora)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-textarea
                label="Justificación *"
                outlined
                maxlength="250"
                v-model="pagoEtapa.justificacion"
                :error-messages="justificacionErrors"
                @blur="$v.pagoEtapa.justificacion.$touch()"
              ></v-textarea>
              <v-col cols="8" class="px-0">
                <v-autocomplete
                  label="Forma de pago *"
                  :items="formasPago"
                  item-value="id"
                  item-text="nombre"
                  outlined
                  v-model="pagoEtapa.id_metodo_pago"
                  :error-messages="formaPagoErrors"
                  @blur="$v.pagoEtapa.id_metodo_pago.$touch()"
                >
                </v-autocomplete>

                <v-autocomplete
                  outlined
                  label="Banco"
                  :items="bancos"
                  item-value="id"
                  item-text="nombre"
                  v-model="pagoEtapa.id_institucion_financiera"
                  :error-messages="bancoErrors"
                  @blur="$v.pagoEtapa.id_institucion_financiera.$touch()"
                  v-if="
                    pagoEtapa.id_metodo_pago === 2 ||
                    pagoEtapa.id_metodo_pago === 3
                  "
                >
                  <!-- <template slot="selection" slot-scope="data">
                      {{ data.item.nombre }} {{ data.item.n_cuenta }}
                    </template>
                    <template slot="item" slot-scope="data">
                      {{ data.item.nombre }} {{ data.item.n_cuenta }}
                    </template> -->
                </v-autocomplete>
                <template v-if="pagoEtapa.id_metodo_pago === 2">
                  <div>
                    <v-text-field
                      outlined
                      label="Número de cheque"
                      v-model="pagoEtapa.numero_cheque"
                      :error-messages="numChequeErrors"
                      @blur="$v.pagoEtapa.numero_cheque.$touch()"
                    ></v-text-field>
                    <v-text-field
                      outlined
                      label="A nombre de"
                      v-model="pagoEtapa.a_nombre_de"
                      :error-messages="aNombreDeErrors"
                      @blur="$v.pagoEtapa.a_nombre_de.$touch()"
                    ></v-text-field>
                  </div>
                </template>
                <template v-if="pagoEtapa.id_metodo_pago === 3">
                  <div>
                    <v-file-input
                      outlined
                      label="Comprobante"
                      prepend-icon=""
                      append-icon="mdi-paperclip"
                      accept=".pdf"
                      v-model="pagoEtapa.comprobante"
                    ></v-file-input>
                  </div>
                </template>
              </v-col>
            </section>
          </v-card-text>
          <v-card-actions>
            <section class="pt-0">
              <v-btn class="mx-3" color="secondary" outlined @click="closeModal"
                >Cerrar</v-btn
              >
              <v-btn class="mx-3" color="secondary" @click="guardarDatos">{{
                pagoEditable ? "Editar" : "Guardar"
              }}</v-btn>
            </section>
          </v-card-actions>
        </section>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  required,
  helpers,
  minLength,
  requiredIf,
} from "vuelidate/lib/validators";
import moment from "moment";
import { mapState, mapMutations } from "vuex";

function validarMonto() {
  return this.pagoEtapa.monto > 0;
}

function validarComprobante() {
  return this.comprobanteSize < 512000 || this.pagoEtapa.comprobante === null;
}

export default {
  name: "ModalPagosComponent",
  props: {
    showModal: Boolean,
    metricasPagos: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      maxFecha: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      menuHora: false,
      formasPago: [],
      bancos: [],
    };
  },

  validations: {
    pagoEtapa: {
      monto: {
        required,
        validarMonto,
      },
      justificacion: { required, minLength: minLength(20) },
      id_metodo_pago: { required },
      id_institucion_financiera: {
        required: requiredIf(function () {
          return (
            this.pagoEtapa.id_metodo_pago === 2 ||
            this.pagoEtapa.id_metodo_pago === 3
          );
        }),
      },
      numero_cheque: {
        required: requiredIf(function () {
          return this.pagoEtapa.id_metodo_pago === 2;
        }),
      },
      a_nombre_de: {
        required: requiredIf(function () {
          return this.pagoEtapa.id_metodo_pago === 2;
        }),
      },
      // comprobante: {
      //   // required: requiredIf(function () {
      //   //   return this.pagoEtapa.id_metodo_pago === 3;
      //   // }),
      //   validarComprobante,
      // },
      fecha: { required },
      hora: { required },
      id_etapa: { required },
    },
  },
  computed: {
    ...mapState("seguimientoOrden", ["pagoEtapa", "pagoEditable"]),
    ...mapState("procesoCompra", ["etapasList"]),
    formattedDate() {
      return this.pagoEtapa.fecha
        ? moment(this.pagoEtapa.fecha).format("DD/MM/YYYY")
        : "";
    },
    formattedHora() {
      return this.pagoEtapa.hora
        ? moment(this.pagoEtapa.hora, "HH:mm:ss aa").format("hh:mm a")
        : "";
    },
    montoErrors() {
      const errors = [];
      if (!this.$v.pagoEtapa.monto.$dirty) return errors;
      !this.$v.pagoEtapa.monto.required && errors.push("Monto es obligatorio");
      !this.$v.pagoEtapa.monto.validarMonto &&
        errors.push("Monto debe ser mayor a cero");

      return errors;
    },
    fechaErrors() {
      const errors = [];
      if (!this.$v.pagoEtapa.fecha.$dirty) return errors;
      !this.$v.pagoEtapa.fecha.required && errors.push("Fecha es obligatorio");

      return errors;
    },
    horaErrors() {
      const errors = [];
      if (!this.$v.pagoEtapa.hora.$dirty) return errors;
      !this.$v.pagoEtapa.hora.required && errors.push("Hora es obligatorio");

      return errors;
    },
    justificacionErrors() {
      const errors = [];
      if (!this.$v.pagoEtapa.justificacion.$dirty) return errors;
      !this.$v.pagoEtapa.justificacion.required &&
        errors.push("Justificación es obligatorio");
      !this.$v.pagoEtapa.justificacion.minLength &&
        errors.push("Justificación debe tener al menos 20 caracteres");
      return errors;
    },
    formaPagoErrors() {
      const errors = [];
      if (!this.$v.pagoEtapa.id_metodo_pago.$dirty) return errors;
      !this.$v.pagoEtapa.id_metodo_pago.required &&
        errors.push("Forma de pago es obligatorio");

      return errors;
    },
    bancoErrors() {
      const errors = [];
      if (!this.$v.pagoEtapa.id_institucion_financiera.$dirty) return errors;
      !this.$v.pagoEtapa.id_institucion_financiera.required &&
        errors.push("Banco es obligatorio");

      return errors;
    },
    // comprobanteErrors() {
    //   const errors = [];
    //   if (!this.$v.pagoEtapa.comprobante.$dirty) return errors;
    //   // !this.$v.pagoEtapa.comprobante.required &&
    //   //   errors.push("Comprobante es obligatorio");
    //   !this.$v.pagoEtapa.comprobante.validarComprobante &&
    //     errors.push("El tamaño máximo del archivo es de 512 KB");

    //   return errors;
    // },
    numChequeErrors() {
      const errors = [];
      if (!this.$v.pagoEtapa.numero_cheque.$dirty) return errors;
      !this.$v.pagoEtapa.numero_cheque.required &&
        errors.push("Número de cheque es obligatorio");

      return errors;
    },
    aNombreDeErrors() {
      const errors = [];
      if (!this.$v.pagoEtapa.a_nombre_de.$dirty) return errors;
      !this.$v.pagoEtapa.a_nombre_de.required &&
        errors.push("A nombre de es obligatorio");

      return errors;
    },
    // comprobanteSize() {
    //   if (this.pagoEtapa.comprobante) {
    //     return this.pagoEtapa.comprobante.size;
    //   }
    // },
    etapaErrors() {
      const errors = [];
      if (!this.$v.pagoEtapa.id_etapa.$dirty) return errors;
      !this.$v.pagoEtapa.id_etapa.required &&
        errors.push("Etapa es obligatorio");
      return errors;
    },
  },
  methods: {
    ...mapMutations("seguimientoOrden", [
      "getPagoEtapa",
      "clearPagoEtapa",
      "getPagoEditable",
    ]),
    closeModal() {
      this.$emit("onClose");
      this.$v.$reset();
      this.clearPagoEtapa();
      this.getPagoEditable(false);
    },
    filterKey(e) {
      const key = e.key;
      if (key === "-") return e.preventDefault();
      if (key === "+") return e.preventDefault();
      if (key === "e") return e.preventDefault();
    },
    restrictDecimal() {
      this.pagoEtapa.monto = this.pagoEtapa.monto.match(/^\d+\.?\d{0,2}/);
    },

    async guardarDatos() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.pagoEtapa.id_tipo_movimiento = 1;
        // if (!this.pagoEditable)
        this.pagoEtapa.fecha_hora = `${this.pagoEtapa.fecha} ${this.pagoEtapa.hora}`;

        let formData = new FormData();
        Object.keys(this.pagoEtapa).forEach((key) => {
          if (this.pagoEtapa[key]) formData.append(key, this.pagoEtapa[key]);
        });

        let response = null;

        if (this.pagoEditable) {
          if (this.pagoEtapa.monto > this.metricasPagos.total_adjudicado) {
            this.temporalAlert({
              show: true,
              message: `El monto del pago no puede ser mayor al monto adjudicado`,
              type: "error",
            });
          } else {
            response = await this.services.ContratoService.putContratoPagoV1(
              this.pagoEtapa.id,
              formData
            );
          }
          
        } else {
          if (+this.pagoEtapa.monto > +this.metricasPagos.monto_restante) { 
            this.temporalAlert({
              show: true,
              message: `El monto del pago no puede ser mayor al monto restante`,
              type: "error",
            });
            return;
          } else {
            response = await this.services.ContratoService.postContratoPagosV1(
              this.pagoEtapa.id_etapa,
              formData
            );
          }
        }

        if (response.status == 201 || response.status == 204) {

          this.temporalAlert({
            show: true,
            message: `Pago ${this.pagoEditable ? "actualizado" : "registrado"}`,
            type: "success",
          });
          this.closeModal();
          this.clearPagoEtapa();
          this.$emit("reloadPagos");
        }
      }
    },

    async getBancos() {
      const { status, data } =
        await this.services.ContratoService.getInstitucionesFinancieras();

      if (status == 200) {
        this.bancos = data;
      }
    },
    async getTiposPagos() {
      const { status, data } =
        await this.services.ContratoService.getTiposPagos();

      if (status == 200) {
        this.formasPago = data;
      }
    },
  },
  created() {
    this.getBancos();
    this.getTiposPagos();
  },
  updated() {},
};
</script>

<style lang="scss" scoped></style>
