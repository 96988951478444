<template>
  <div>
    <v-dialog :max-width="750" v-model="showModal" @click:outside="closeModal">
      <v-card>
        <v-btn class="mt-2 ml-3" icon @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        
        <v-card-title class="d-flex align-center justify-space-between">
          <div class="d-flex align-center">
            <v-icon class="mr-2"
              >mdi-clipboard-list-outline</v-icon
            >
            <span class="font-weight-bold mr-2">Detalle del pago</span>
          </div>
        </v-card-title>

        <v-card-text>
          <div style="max-height: 500px; overflow-y: auto;">
            <section>
              <v-row justify="center" align="center" no-gutters>
                <v-col cols="6">
                  <div class="d-flex flex-column">
                    <p class="font-weight-medium primary--text mb-0">
                      Fecha movimiento:
                    </p>
                    <p class="text-subtitle-1 font-weight-bold primary--text">
                      {{
                        moment(pagoDetail.fecha_hora_movimiento).format(
                          "DD/MM/YYYY hh:mm a"
                        )
                      }}
                    </p>
                    <p class="font-weight-medium primary--text mb-0">Monto:</p>
                    <p class="text-subtitle-1 font-weight-bold primary--text">
                      ${{ pagoDetail.monto }}
                    </p>
                    <p class="font-weight-medium primary--text mb-0">
                      Método de pago:
                    </p>
                    <p v-if="pagoDetail?.detalle" class="text-subtitle-1 font-weight-bold primary--text">
                      {{ pagoDetail?.detalle?.metodo_pago }}
                    </p>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="d-flex flex-column">
                    <p class="font-weight-medium primary--text mb-0">
                      Justificación:
                    </p>
                    <p class="text-subtitle-1 font-weight-bold primary--text">
                      {{ pagoDetail.justificacion }}
                    </p>
                    <div v-if="pagoDetail?.detalle" class="d-flex flex-column mx-4">
                      <template
                        v-if="pagoDetail?.detalle?.institucion_financiera"
                      >
                        <p class="font-weight-medium primary--text mb-0">
                          Institución financiera:
                        </p>
                        <p class="text-subtitle-1 font-weight-bold primary--text">
                          {{ pagoDetail?.detalle?.institucion_financiera }}
                        </p>
                      </template>
                      <template v-if="pagoDetail?.detalle?.numero_cheque">
                        <p class="font-weight-medium primary--text mb-0">
                          Número de cheque:
                        </p>
                        <p class="text-subtitle-1 font-weight-bold primary--text">
                          {{ pagoDetail?.detalle?.numero_cheque }}
                        </p>
                      </template>
                      <template v-if="pagoDetail?.detalle?.a_nombre_de">
                        <p class="font-weight-medium primary--text mb-0">
                          A nombre de:
                        </p>
                        <p class="text-subtitle-1 font-weight-bold primary--text">
                          {{ pagoDetail?.detalle?.a_nombre_de }}
                        </p>
                      </template>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </section>
          </div>
        </v-card-text>
        
        <v-row no-gutters class="d-flex justify-center align-center pr-2 pl-2" style="gap: 16px;">
          <v-btn class="text-uppercase flex-grow-1 secondary mb-2" outlined @click="closeModal">Regresar</v-btn>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "ModalDetallePagosComponent",
  data() {
    return {};
  },
  props: {
    pagoDetail: {
      type: Object,
      default: () => {},
    },
    showModal: Boolean,
  },
  computed: {
    formattedDate() {
      return this.fecha ? moment(this.fecha).format("DD/MM/YYYY") : "";
    },
    formattedHora() {
      return this.hora
        ? moment(this.hora, "HH:mm:ss aa").format("hh:mm a")
        : "";
    },
  },
  methods: {
    closeModal() {
      this.$emit("onClose");
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped></style>
